<template>
<div class="h-screen text-center">
  <TextNr1/>
  <TextNr2/>
  <TextNr3/>
  <TextNr4/>
</div>

</template>

<script>

import TextNr1 from '@/components/animations/TextPlugin/TextNr1.vue'
import TextNr2 from '@/components/animations/TextPlugin/TextNr2.vue'
import TextNr3 from '@/components/animations/TextPlugin/TextNr3.vue'
import TextNr4 from '@/components/animations/TextPlugin/TextNr4.vue'


export default {
  name: "TextPlugin",

  components: {
    TextNr1,TextNr2,TextNr3,TextNr4
  },

}
</script>

<style scoped>



div {

  color: #888;
  background-color: #333;
  text-transform: uppercase;

}




</style>