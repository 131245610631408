<template>
  <h2 class="text-white">Nr.3</h2>

  <div id="3" class="container mx-auto py-4">

    <h1>
      <span  class="box"></span>
      <span class="hi px-2 mr-2">Hi, I'm</span>
      <span  class="text"></span>
      <span class="cursor">_</span>
    </h1>

  </div>
</template>

<script>

import {gsap} from "gsap";
import { TextPlugin } from 'gsap/TextPlugin'

gsap.registerPlugin(TextPlugin);

export default {
  name: "TextNr3",

  mounted() {


    gsap.to('.cursor', { //blink cursor
      opacity: 0, ease: 'power2.inOut', repeat: -1
    })


    let boxTl = gsap.timeline()

    boxTl.to('.box', {
      duration: 1,
      width: '21%',
      delay: 0.5,
      ease: 'power4.inOut'
    })
        .from('.hi', {
          duration: 1,
          y: '7vw',
          ease: 'power3.out',
          onComplete: () => masterTl.play()
        }, '-=1')
        .to('.box', {
          duration: 1,
          height: '7vw',
          ease: 'elastic.out'
        })
        .to('.box', {
          duration: 2,
          autoAlpha: 0.5,
          yoyo: true,
          repeat: -1,
          ease: "rough({template: none.out, strength: 1, points: 20, taper: 'none', randomize: true, clamp: false})"
        })

    const words = [' Paul.', ' HTML coder', ' Front-end developer']


    let masterTl = gsap.timeline({repeat: -1}).pause()

    words.forEach(word => {
      let tl = gsap.timeline({repeat: 1, yoyo: true, repeatDelay: 1.5})
      tl.to('.text', {
        duration: 2,
        text: word
      })
      masterTl.add(tl)
    })
  }

}
</script>

<style scoped>

h1 {
  position: relative;
  font-size: 4vw;
  font-family: monospace;
  overflow: hidden;
  color: white;
}
.box {
  position: absolute;
  bottom: 0;
  display: inline-block;
  background-color: #2800ad;
  height: 1vw;
  z-index: -1;
}
.hi {display: inline-block;
  background-color: #2800ad;
}


</style>