<template>
  <h2 class="text-white py-4">Nr.2</h2>

  <div id="2" class="container mx-auto py-4 text-5xl font-bold">

    We&nbsp;<span ref="text2"  id="xyz">make</span>&nbsp;it solid.
  </div>
</template>

<script>

import {gsap} from "gsap";
import { TextPlugin } from 'gsap/TextPlugin'

gsap.registerPlugin(TextPlugin);

export default {
  name: "TextNr2",

  mounted() {

    var textPlugin = gsap.timeline({yoyo: true, repeat: -1, repeatDelay: 1.5});


    textPlugin
        .to(this.$refs.text2, .7, {
          text: "design",
          color: "#EDFF00"
        })

    textPlugin.to(this.$refs.text2, .7, {
      text: "code",
      color: "#ED1E79"
    }, "+=1.5")

    textPlugin.to(this.$refs.text2, .7, {
      text: "brand",
      color: "#0BFCF0"
    }, "+=1.5");



  }
}
</script>

<style scoped>

</style>