<template>

  <h2 class="text-white py-4">Nr.4</h2>
  <h1>
    <span class="title ">This is</span>
    <span class="title">a long</span>
    <span class="title">long title</span>
  </h1>
</template>

<script>

import {gsap} from "gsap";
import { TextPlugin } from 'gsap/TextPlugin'

gsap.registerPlugin(TextPlugin);


export default {
  name: "TextNr4",



  mounted() {

    const title1 = gsap.timeline();
    title1.fromTo("span", {
      duration: 0.4,
      ease: "back(1.7)",
      opacity: 0,
      bottom: -80,

    }, {

      duration: 0.7,
        ease: "back(1.7)",
        opacity: 1,
        bottom: 0,

      yoyo: true,
      stagger: {
        amount:3,
        each: 0.1,
      }


    })


  }


}
</script>

<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Fjalla+One);

$background: linear-gradient(to bottom, #405166 0%,#656f6f 100%);
$red: #e55643 ;
$green: #2b9f5e;
$yellow: #f1c83c;
$shadow: #533d4a;

html{
  height: 100%;
}

body{
  font-family: 'Fjalla One', sans-serif;
  background: $background;
}


.button{

  position: relative;
  bottom: -65px;
  left: 50%;
  transform:  rotate(-10deg);
  color: $red;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;

span {
  transform: skew(-10deg);
  display: block;

  text-shadow: $shadow 1px 1px, $shadow 2px 2px, $shadow 3px 3px, $shadow 4px 4px;
}
}

h1{
  color: #fff;
  text-transform: uppercase;
  font-size: 42px;
  margin: 0;
  line-height: 47px;
  letter-spacing: 2px;
}

.title{
  transform:  rotate(-10deg);
  display: block;
  position: relative;
  text-shadow: $shadow 1px 1px, $shadow 2px 2px, $shadow 3px 3px, $shadow 4px 4px;

span {
  transform: skew(-10deg);
  display: block;
  min-width: 10px;
  min-height: 10px;
  position: relative;
}
}

.title{
&:nth-child(1){
   color: $red;
 }
&:nth-child(2){
   color: $green;
 }
&:nth-child(3){
   color: $yellow;
 }
}
</style>