<template>

  <h2 class="text-white py-4">Nr.1</h2>
  <div class="container mx-auto ">

    <h1 ref="text1" class="text-2xl text-white">Acourse</h1>
  </div>


</template>

<script>
import {gsap} from "gsap";
import { TextPlugin } from 'gsap/TextPlugin'

gsap.registerPlugin(TextPlugin);


export default {
  name: "TextNr1",

  mounted() {

    const textPlugin = gsap.timeline({yoyo: true, repeat: -1, repeatDelay: .3});


    textPlugin.to(this.$refs.text1, {
          duration: .5,
          text: '( ͡° ͜ʖ ͡°)',
          ease: "none"
        },
    )

    textPlugin.to(this.$refs.text1, {
          duration: .5,
          text: 'Design',
          ease: "none"
        },
    )

  }

}
</script>

<style scoped>

</style>